import React, { useEffect, useState } from 'react'
import { Advantages, CrossContainer, FreePackage, Heading, MumayazPackage, PaymentButtonContainer, PaymentOptionButton, PaymentOptionContainer, PaymentOptionHeading, PaymentOptionMainContainer, Price, SmallText, StyledButton, StyledPlusIcon, SubPrice, SubscriptionContent, SubscriptionModalContainer, SubscriptionModalSubContainer, TableBody, TableContainer, TableHeader, TableRow, TableWrapper, TextSection, Wrapper } from './SubscriptionModal.style'
import PlusIcon from "../../../Assets/Images/PlusIcon.svg"
import { useSelector, useDispatch } from 'react-redux'
import { SubscriptionLanguage } from '../../data/Subscription.lang'
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { setVisibility } from '../../../Store/Slice/SubscriptionModal/SubscriptionModal.slice'
import { useNavigate } from 'react-router-dom'
import { setAmount, setPremiumDetails } from '../../../Store/Slice/PremiumDetails/PremiumDetailsSlice'
import ApiClient from '../../../Config/index.config'
import VisaImage from "../../../Assets/Images/VisaMasterCardLogo.svg"
import MadaImage from "../../../Assets/Images/MadaLogo.svg"
import AppleImage from "../../../Assets/Images/ApplePayLogo.png"

const SubscriptionModal = ({subscriptionVisibility, setSubscriptionVisibility, setLimitExhaustModal}) => {
    
    const dispatch = useDispatch();
    const [paymentOption, setPaymentOption] = useState(false);
    const lang = useSelector((store) => store?.lang?.language)
    const Amount = useSelector((store) => store?.premiumDetails?.Amount)
    const premiumFeaturesPrice = useSelector((store) => store?.premiumDetails?.Data)
    const FreeSize = premiumFeaturesPrice?.free_user_max_file_size;
    const PremiumSize = premiumFeaturesPrice?.premium_user_max_file_size
    const FreeUploadLimit = premiumFeaturesPrice?.free_user_number_of_files
    const premiumUploadLimit = premiumFeaturesPrice?.premium_user_number_of_files
    const freePages = premiumFeaturesPrice?.free_user_number_of_pages    
    const premiumPages = premiumFeaturesPrice?.premium_user_number_of_pages
    const freeExplain = premiumFeaturesPrice?.free_user_number_of_explanations
    const premiumExplain = premiumFeaturesPrice?.premium_user_number_of_explanations
    const freeChats = premiumFeaturesPrice?.free_user_number_of_chat_messages
    const premiumChats = premiumFeaturesPrice?.premium_user_number_of_chat_messages
    const freeFlashCards = premiumFeaturesPrice?.free_user_number_of_flash_cards
    const premiumFlashCards = premiumFeaturesPrice?.premium_user_number_of_flash_cards
    const freeQuiz = premiumFeaturesPrice?.free_user_number_of_questions
    const premiumQuiz = premiumFeaturesPrice?.premium_user_number_of_questions

    const subcriptionPlans = 
    [
        {advantage: SubscriptionLanguage[lang]?.file, free: FreeUploadLimit + " " + SubscriptionLanguage[lang]?.freeFileLimit, mumayaz: premiumUploadLimit + " " + SubscriptionLanguage[lang]?.MumayazFileLimit, icon: SubscriptionLanguage[lang]?.fileIcon},
        {advantage: SubscriptionLanguage[lang]?.page, free: freePages + " " + SubscriptionLanguage[lang]?.freePageNumbers, mumayaz: premiumPages + " " + SubscriptionLanguage[lang]?.mumayazPageNumber, icon: SubscriptionLanguage[lang]?.pagesIcon},
        {advantage: SubscriptionLanguage[lang]?.size, free: FreeSize + " " + SubscriptionLanguage[lang]?.freeFileSize, mumayaz: PremiumSize + " " + SubscriptionLanguage[lang]?.mumayazFileSize, icon: SubscriptionLanguage[lang]?.fileUploadIcon},
        {advantage: SubscriptionLanguage[lang]?.explain, free: freeExplain + " " + SubscriptionLanguage[lang]?.freeExplanationCount, mumayaz: premiumExplain || SubscriptionLanguage[lang]?.mumayzExplanationCount, icon: SubscriptionLanguage[lang]?.explainIcon},
        {advantage: SubscriptionLanguage[lang]?.chats, free: freeChats + " " + SubscriptionLanguage[lang]?.freeChats, mumayaz: premiumChats + " " +  SubscriptionLanguage[lang]?.premiumChats, icon: SubscriptionLanguage[lang]?.chatIcon},
        {advantage: SubscriptionLanguage[lang]?.flashCards, free: freeFlashCards + " " +  SubscriptionLanguage[lang]?.freeFlashCards, mumayaz: premiumFlashCards || SubscriptionLanguage[lang]?.premiumFlashCards, icon: SubscriptionLanguage[lang]?.flashCardIcon},
        {advantage: SubscriptionLanguage[lang]?.quiz, free: freeQuiz + " " + SubscriptionLanguage[lang]?.freeQuiz, mumayaz: premiumQuiz || SubscriptionLanguage[lang]?.premiumQuiz, icon: SubscriptionLanguage[lang]?.quizIcon}
    ]

    const getPremiumDetails = async () => {
        try{
          const response = await ApiClient.get("library/premium-details")
          dispatch(setPremiumDetails(response.data.data.feature_data))
          dispatch(setAmount(response.data.data.amount))
        }
        catch(error){
          console.log(error);
        }
    }
    
    const handleClose = () => {
        setSubscriptionVisibility(false);
        dispatch(setVisibility(false))
        if(setLimitExhaustModal){
            setLimitExhaustModal(false);
        }
    }

    const handleSubscribe = () => {
        setPaymentOption(true)
    }


    // Handeling Body scroll when modal is open
    useEffect(() => {
        getPremiumDetails();
        if(subscriptionVisibility){
            if (typeof window != 'undefined' && window.document) {
                document.body.style.overflow = 'hidden';
            }
        }

        return () => {
            document.body.style.overflow = 'unset';
        }

    },[])

    return (
        <SubscriptionModalContainer>
            <SubscriptionModalSubContainer>
                <SubscriptionContent>

                    <CrossContainer>
                        <svg style={{cursor: "pointer"}} onClick={() => handleClose()} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 1.83398L1 13.834M1 1.83398L13 13.834" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </CrossContainer>

                    <StyledPlusIcon src={PlusIcon} alt='plus icon'/>

                    <TextSection width={"70%"}>
                        <Heading margintop={"0rem"}>
                            {SubscriptionLanguage[lang].heading}
                        </Heading>
                        {SubscriptionLanguage[lang].subHeading}
                    </TextSection>

                    <Price>
                        {Amount} <SubPrice>{SubscriptionLanguage[lang].price}<SmallText lang={lang}>/ {SubscriptionLanguage[lang].month}</SmallText></SubPrice>
                    </Price>

                    <TableWrapper>
                            <TableContainer>
                                <TableBody>
                                <TableRow>
                                    <TableHeader lang={lang}>{SubscriptionLanguage[lang].advantages}</TableHeader>
                                    <TableHeader lang={lang}>{SubscriptionLanguage[lang].free} <span style={{fontSize: "14px", color: "#4C4C4C", fontWeight:"normal"}}>{lang==='en'? "(current)" : "( الحالية )"}</span></TableHeader>
                                    <TableHeader lang={lang} style={{color: "#4D2C77"}}>{SubscriptionLanguage[lang].mumayaz}</TableHeader>
                                </TableRow>
                                {subcriptionPlans.map((data, index) => {
                                    return (
                                        <TableRow lang={lang} key={index}>
                                            <Advantages lang={lang}>
                                                <span style={{display: "flex", alignItems: "center", gap: "8px"}}>
                                                    <img src={data.icon} alt=""/>
                                                    {data.advantage}
                                                </span>
                                            </Advantages>
                                            <FreePackage lang={lang}>
                                                <Wrapper>
                                                    <IoIosCheckmarkCircleOutline style={{color: "#727272", fontSize:"20px"}}/>
                                                    {data.free}
                                                </Wrapper>
                                            </FreePackage>
                                            <MumayazPackage lang={lang}>
                                                <Wrapper>
                                                    <IoIosCheckmarkCircle style={{color: "#CA56A1", fontSize:"20px"}}/>
                                                    {data.mumayaz}
                                                </Wrapper>
                                            </MumayazPackage>
                                        </TableRow>
                                    )
                                })}
                                </TableBody>
                            </TableContainer>
                    </TableWrapper>


                </SubscriptionContent>
                <StyledButton onClick={handleSubscribe}>{SubscriptionLanguage[lang].subscriptionButton}</StyledButton>
            </SubscriptionModalSubContainer>
            {paymentOption ?
                <PaymentOptionModal handleClose={handleClose} setPaymentOption={setPaymentOption}></PaymentOptionModal>
            :null}
        </SubscriptionModalContainer>
    )
}

export default SubscriptionModal;


const PaymentOptionModal = ({handleClose, setPaymentOption}) => {
    const navigate = useNavigate();
    const lang = useSelector((store) => store?.lang?.language)
    const handleSelect = (data) => {
        setPaymentOption(false);
        handleClose();
        navigate('/payment', { state: { brands: data } });
    }
    return (
        <PaymentOptionContainer>
                    <PaymentOptionMainContainer>
                        <CrossContainer>
                            <svg style={{cursor: "pointer"}} onClick={() => setPaymentOption(false)} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1.83398L1 13.834M1 1.83398L13 13.834" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </CrossContainer>
                        <PaymentOptionHeading>
                            {SubscriptionLanguage[lang]?.PaymentOptionHeading}
                        </PaymentOptionHeading>
                        <PaymentButtonContainer>

                            <PaymentOptionButton onClick={() => handleSelect("VISA MASTER")}>
                                <img src={VisaImage} alt="img"/>
                                {/* {SubscriptionLanguage[lang]?.VisaMaster} */}
                            </PaymentOptionButton>
                            <PaymentOptionButton onClick={() => handleSelect("MADA")}>
                                <img src={MadaImage} alt="img"/>
                                {/* {SubscriptionLanguage[lang]?.Mada} */}
                            </PaymentOptionButton>
                            {/* <PaymentOptionButton onClick={() => handleSelect("APPLE")}>
                                <img style={{width: "60%", height: "60%"}} src={AppleImage} alt="img"/>
                            </PaymentOptionButton> */}
                        </PaymentButtonContainer >
                    </PaymentOptionMainContainer>
                </PaymentOptionContainer>
    )
}