import React, { useEffect, useState } from 'react'
import Taost from '../Toast/Toast';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../Error/Errorcode';
import { Dropdown, DropdownButton, OverviewError, Overviewcontainer, Selectionfeature } from './public.style';
import LibraryBlogCard from './LibraryBlogCard';
import { Auth } from '../../Services/api';
import { useSelector } from 'react-redux';
import { LibBlog } from "../Blogs/blogs.styles";
import { home } from "../../Services/api";
import { model, model1, model2 } from '../data/model.lang';
import { educationData } from '../data/profile.lang';
import {useDispatch} from 'react-redux';
import { setPublic } from '../../Store/Slice/PublicLibrary/Public.Slice';
import { publicLang } from '../data/public.lang';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiClient from '../../Config/index.config';
import { setLibraryData } from '../../Store/Slice/LibraryData/LibraryDataSlice';
import { removeRoutesArray, updateRoutesArray } from '../../Store/Slice/Navigation/NavigationSlice';
import { Loader, LoaderCenter } from '../Auth/Auth.Style';
import UploadPDF from './Uploadfile';
import LibraryBlog from './LibrarBlogoverview';




const Overview = () => {
    const lang = useSelector((store) => store?.lang?.language);
    const [degree, setDegree] = useState("");
    const [college, setcollege] = useState("");
    const [specialization, setspecialization] = useState("");
    const [allcollege, setallcollege] = useState([]);
    const [alldata, setalldata] = useState([]);
    const [allsecilization, setallsecilization] = useState([]);
    const Searchdegree=useSelector((store)=>store?.public?.academic_level);
    const Searchcollege=useSelector((store)=>store?.public?.college);
    const Searchspecialization=useSelector((store)=>store?.public?.specialization);
    const [loading,setLoading]=useState(true);
    const dispatch = useDispatch();
   /* Get the profile data */
    const getProfileData = async () => {
        try {
          setDegree(Searchdegree);
          setcollege(Searchcollege);
          setspecialization(Searchspecialization);
          const data2 = await Auth.getEducationData();
          const unidata2=await Auth.getUniversity(degree);
          const clgdata2 = await Auth.getCollege(college,degree );
          setalldata(data2?.data?.data);
          setallcollege(unidata2?.data?.data);
          setallsecilization(clgdata2?.data?.data);
          setLoading(false);
        } catch (error) {
         {/* Error handling */}
          const errorcode = error?.response?.data?.error_code;
          const message = getErrorMessage(errorcode, lang);
          toast(<Taost message={message} error />);
        }
      };
    useEffect(() => {
        getProfileData();
      }, []);


    /* handle academic level */
    const handleAcademicLevel = async (inputData) => {
      try {
           const unidata2=await Auth.getUniversity(inputData);
           setcollege("");
           setDegree(inputData);
           setallcollege(unidata2?.data?.data);
           setallsecilization([]);
           setspecialization("");
      } catch (error) {
        const errorcode = error?.response?.data?.error_code;
        const message = getErrorMessage(errorcode, lang);
        toast(<Taost message={message} error />);
      }
    };


  /* handle college name */
  const handlecollegename = async (inputData) => {
    try {
      setcollege(inputData);
      const clgdata2 = await Auth.getCollege(inputData,degree);
      setallsecilization(clgdata2?.data?.data);
      setspecialization("");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };


  /* handle specialization */
  const handleSpecialization = async (inputData) => {
    try {
      setspecialization(inputData);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };
   
  
  const handleSearch=() => {
    const data = {
      academic_level: degree,
      college: college,
      specialization: specialization
    };
    dispatch(setPublic(data));
  };

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Overviewcontainer>
        <Selectionfeature >

                {/* education level selection */}
                <Dropdown>
                    <p>{model1[lang]?.academic}</p>
                    <select value={degree} onChange={(e)=>handleAcademicLevel(e.target.value)}>
                        <option disabled value={null}>{model[lang].select}</option>
                        {alldata?.map((data, index) => {  
                            return (
                                <option key={data} value={data.tmyyoz_id}>{data.name}</option>
                            );
                        }
                        )}
                    </select>
                </Dropdown>
                
                {/* University selection */}
                <Dropdown>
                    <p>{model2[lang]?.select}</p>
                    <select value={college} onChange={(e)=>handlecollegename(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {allcollege?.map((data, index) => {
                            return (
                                <option key={data} value={data?.tmyyoz_id}>{data?.name}</option>
                            );    
                        }
                        )}
                    </select>
                </Dropdown>

                 {/* Specialization selection  */}
                <Dropdown >
                    <p>{educationData[lang]?.specialization}</p>
                    <select value={specialization} onChange={(e)=>handleSpecialization(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {allsecilization?.map((data, index) => {
                            return (
                                <option key={data} value={data.tmyyoz_id}>{data.name}</option>
                            );
                        }
                        )}  
                    </select>
                </Dropdown>
                <DropdownButton  onClick={()=>handleSearch()}>
                  {loading?<LoaderCenter><Loader/></LoaderCenter>:model[lang]?.search}
                </DropdownButton>
            </Selectionfeature>
        <LibraryBlog />
    </Overviewcontainer>
  )
}



export default Overview;