export const Bloglang = {
  ar: {
    heading: "أحدث الملخصات",
    description:
      "أنت عليك ترفع ملفك والذكاء الاصطناعي مهمته يلخصه لك",
    button:"اِسْتَكْشف المزيد",
  },
  en: {
    heading: "Latest summaries",
    description:
      "Enjoy a personalized learning adventure with Tamayoz, benefit from comprehensive resources and distinctive courses, and achieve academic success with us, discover unique opportunities and prepare your bright future!",
    button: "Explore more",
  },
};
