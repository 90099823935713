import React, { useEffect, useState } from 'react'
import {  Description, Dropdown, DropdownButton, Filterdatacontainer, Filterdatawrapper, Header, Selectionfeature } from './public.style'
import { useSelector } from 'react-redux'
import { publicLang } from '../data/public.lang';
import { getErrorMessage } from '../Error/Errorcode';
import { toast } from 'react-toastify';
import Taost from '../Toast/Toast';
import { Auth } from '../../Services/api';
import { model, model1, model2 } from '../data/model.lang';
import { educationData } from '../data/profile.lang';
import { useNavigate } from 'react-router-dom';
import { setPublic } from '../../Store/Slice/PublicLibrary/Public.Slice';
import {useDispatch} from 'react-redux';
import { Loader, LoaderCenter } from '../Auth/Auth.Style';


const Filterdata = () => {
    const lang = useSelector((store) => store?.lang?.language);
    const [degree, setDegree] = useState(null);
    const [college, setcollege] = useState(null);
    const [specialization, setspecialization] = useState(null);
    const [allcollege, setallcollege] = useState([]);
    const [alldata, setalldata] = useState([]);
    const [allsecilization, setallsecilization] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [Loading,setLoading]=useState(true)
    
   {/* Get the profile data */}
    const getProfileData = async () => {
        try {
          const data2 = await Auth.getEducationData();
          const unidata2=await Auth.getUniversity();
          setalldata(data2?.data?.data);
          setallcollege(unidata2?.data?.data);
          setcollege("");
          setspecialization("");
          setLoading(false)
        } catch (error) {
          const errorcode = error?.response?.data?.error_code;
          const message = getErrorMessage(errorcode, lang);
          toast(<Taost message={message} error />);
        }
      };

    useEffect(() => {
        getProfileData();
      }, []);

  {/* handle academic level */}
  const handleAcademicLevel = async (inputData) => {
    try {
         const unidata2=await Auth.getUniversity(inputData);
         setcollege("");
         setDegree(inputData);
         setallcollege(unidata2?.data?.data);
         setallsecilization([]);
         setspecialization("");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  {/* handle college name */}
  const handlecollegename = async (inputData) => {
    try {
      setcollege(inputData);
      const clgdata2 = await Auth.getCollege(inputData,degree);
      setallsecilization(clgdata2?.data?.data);
      setspecialization("");
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  {/* handle specialization */}
  const handleSpecialization = async (inputData) => {
    try {
      setspecialization(inputData);
    } catch (error) {
      const errorcode = error?.response?.data?.error_code;
      const message = getErrorMessage(errorcode, lang);
      toast(<Taost message={message} error />);
    }
  };

  {/* handle search */}
  const handleSearch=() => {
    const data = {
      academic_level: degree,
      college: college,
      specialization: specialization
    };
    dispatch(setPublic(data));
    navigate('/overview');
  };
    

  return (
    <Filterdatacontainer>
        <Filterdatawrapper>
            <Header>{publicLang[lang].Header} <span style={{color:"#ca56a1"}}>{publicLang[lang].span}</span></Header>
            <Header>{publicLang[lang].Header2}</Header>
            <Description>{publicLang[lang].decription}</Description>
            <Selectionfeature>

                {/* education level selection */}
                <Dropdown>
                    <p>{model1[lang]?.academic}</p>
                    <select value={degree} onChange={(e)=>handleAcademicLevel(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {alldata?.map((data, index) => {  
                            return (<option key={data} value={data.tmyyoz_id}>{data.name}</option>);
                        })}
                    </select>
                </Dropdown>

                {/* University selection */}
                <Dropdown>
                    <p>{model2[lang]?.select}</p>
                    <select value={college} onChange={(e)=>handlecollegename(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {allcollege?.map((data, index) => {
                            return (<option key={data} value={data.tmyyoz_id}>{data.name}</option>);    
                        })}
                    </select>
                </Dropdown>

                {/* Specialization selection */}
                <Dropdown>
                    <p>{educationData[lang]?.specialization}</p>
                    <select value={specialization} onChange={(e)=>handleSpecialization(e.target.value)}>
                        <option disabled value="">{model[lang].select}</option>
                        {allsecilization?.map((data, index) => {
                            return (<option key={data} value={data.tmyyoz_id}>{data.name}</option>);
                        })}  
                    </select>
                </Dropdown>
                <DropdownButton onClick={()=>handleSearch()}>
                  {Loading?<LoaderCenter><Loader/></LoaderCenter> : model[lang]?.search}
                  </DropdownButton>
            </Selectionfeature>
        </Filterdatawrapper>
    </Filterdatacontainer>
  )
}

export default Filterdata;