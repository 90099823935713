export const CreateFolderLanguage = {
    ar: {
        heading: "إنشاء مجلد جديد",
        subHeadingSmall: "نظم ملفاتك في مجلدات مخصصة لكل مادة ",
        editHeading: "تعديل الملف",
        saveButton: "إنشاء مجلد",
        subHeading: "نظم ملفاتك في مجلدات مخصصة",
        folderTitle: "عنوان المجلد",
        keepButton: "حفظ",
        createFolderButton: "إنشاء مجلد",
        publicButton: "عام",
        privateButton: "خاص",
        fileAccessStatus: "حالة الوصول للملف"
    },

    en: {
        heading: "Create a new folder",
        subHeadingSmall: "Organize your files into folders dedicated to each topic.",
        editHeading: "Edit File",
        saveButton: "Save",
        subHeading: "By organizing your files into dedicated folders for each topic, you can improve your tracking And share your performance more efficiently.",
        folderTitle: "Folder title",
        createFolderButton: "Create a folder",
        publicButton: "Public",
        privateButton: "Private",
        fileAccessStatus: "File access status",
    },
}