import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Details, Head3, Head4, Head5, ProfileEdit, ProfileForm } from './Profilestyle';
import { subscription } from '../data/profile.lang';
import { Auth } from '../../Services/api';
import SubscriptionModal from '../Modals/SubscriptionModal/SubscriptionModal';

const Subscription = () => {
    const lang=useSelector((store)=>store?.lang?.language);
    const [amount,setAmount]=useState("");
    const [date,setdate]=useState("");
    const [subscriptionVisibility, setSubscriptionVisibility]=useState(false);
    const [limitExhaustModel,setLimitExhaustModal]=useState(false);

    const fetchdata=async ()=>{
      try{
        const data=await Auth.getpremium();
        setAmount(data?.data?.data?.amount);
        setdate(data?.data?.data?.end_date)
      }
      catch(error){
       setSubscriptionVisibility(true);
      }
     
    }
    useEffect(()=>{
      fetchdata();
    },[]);
  return (
    <>
    {subscriptionVisibility ? <SubscriptionModal subscriptionVisibility={subscriptionVisibility} setLimitExhaustModal={setLimitExhaustModal} setSubscriptionVisibility={setSubscriptionVisibility}/>:null}
    <ProfileEdit>
      <ProfileForm>
      <Head3>{subscription[lang]?.subscription}</Head3>
      <Head4>{subscription[lang]?.subscribed}</Head4>
      <Details>
        <div>
            <Head5>{subscription[lang]?.plan}</Head5>
            <p> {amount} {amount?subscription[lang]?.option:subscription[lang]?.error}</p>
        </div>
        <div>
            <Head5>{subscription[lang]?.expire}</Head5>
            <p> {date ?"":subscription[lang]?.error}  {date}</p>
        </div>
      </Details>
      </ProfileForm>
    </ProfileEdit>
  </>
  )
}

export default Subscription;
