import React, { useEffect, useState } from 'react'
import { FolderSelectionSection, FoldersSection, HeadingSection, Text, MainHeading, SubHeading, IconSection, FolderNameSection, CreateNewFolderSection, ButtonsSection, StyledSVG, StyledButton, FileName, ScrollableFolderNamesSection, FileAccessStatusSection, AccessButton, FileNameInput, EmptyFolder, NewFolderContainer, LoaderOuterContainer, NewFolderButton, SubHeadingSmall } from './UploadFile.styles'
import LeftArrowIcon from "../../Assets/Images/LeftArrowIcon.svg"
import FolderCreationModal from '../Modals/FolderCreationModal/FolderCreationModal'
import SubscriptionModal from '../Modals/SubscriptionModal/SubscriptionModal'
import ApiClient from '../../Config/index.config'
import { setUpdation } from '../../Store/Slice/LibraryData/LibraryDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FileSavingLanguage } from '../data/UploadFile.lang'
import { emptyFileTransferRoute, removeFileTransferRoute, setFileTransferRouteUpdated, updateFileTransferRoute } from '../../Store/Slice/Navigation/NavigationSlice'
import FileTransferNavigation from '../NavigationBar/FileTransferNavigation'
import Loader from '../Loader/Loader'
import FolderIcon from "../../Assets/Images/FolderIcon.svg"
import { NavigationName } from '../Library/Library.styles'
import { useNavigate } from 'react-router-dom'

const FileSavingSection = ({setCreateFileModal, setVisibilityOfUploadSection, setVisibilityOfAnimation, fileName, s3_link}) => {

    const [createFolderModal, setCreateFolderModal] = useState(false);
    const [subscriptionVisibility, setSubscriptionVisibility] = useState(false);
    const [borderColorGeneral, setBorderColorGeneral] = useState("#f4f4f4")
    const [backgroundColorGeneral, setBackgroundColorGeneral] = useState("white")
    const [borderColorSpecial, setBorderColorSpecial] = useState("#63B4FF")
    const [backgroundColorSpecial, setBackgroundColorSpecial] = useState("rgba(99, 180, 255, 0.2)");
    const [currentFolder, setCurrentFolder] = useState("library");
    const [folders, setFolders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [parentUUID, setParentUUID] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [editedFileName, setEditedFileName] = useState(fileName);
    const dispatch = useDispatch()
    const lang = useSelector((store) => store.lang.language)
    const initialId = useSelector(store => store.navigationData.routesArray)
    const NavigationData = useSelector((store) => store.navigationData.fileTransferRoute);
    const navigate = useNavigate()

    const handleSpecialClick = () => {
        setIsPublic(false)
        setBackgroundColorSpecial("rgba(99, 180, 255, 0.2)")
        setBorderColorSpecial("#63B4FF")
        setBackgroundColorGeneral("white")
        setBorderColorGeneral("#f4f4f4")
    }

    const handleGeneralClick = () => {
        setIsPublic(true);
        setBackgroundColorGeneral("#E6F5EE")
        setBorderColorGeneral("#75C7A1")
        setBackgroundColorSpecial("white")
        setBorderColorSpecial("#f4f4f4")
    }

    const setData = async () => {
        try{
            const response = await ApiClient.post(`/library/` + parentUUID, {
                "name":`${editedFileName}`,
                "type":"document",
                "s3_link": s3_link,
                "is_public": `${isPublic}`
            })
            // setSubscriptionVisibility(true)
            console.log(response);
            navigate(`/flash-card/${response.data?.message?.item_id}`);
        }catch(error){
            console.log(error)
            // setSubscriptionVisibility(true)
        }
        dispatch(setUpdation());
        if(setCreateFileModal){
            setCreateFileModal(false)
        }
        if(setVisibilityOfUploadSection){
            setVisibilityOfUploadSection(true)
            setVisibilityOfAnimation(false)
        }
        dispatch(setFileTransferRouteUpdated());
    };

    const getFolders = async (data, id) => {
        setIsLoading(true);
        try{
          const response = await ApiClient.get(`/library/` + id)
          setFolders(response.data.data.library)
          if(data !== null || id !== null){
            navigationArray(data, id)
          }
          setIsLoading(false)
        }catch(error){
          console.log(error)
          navigationArray(data, id)
          if(error?.response?.data?.error_code === 1019){
            setFolders([]);
          }
          setIsLoading(false)
        }
    };
    
    const navigationArray = (data, id) => {
        if(NavigationData?.find((x) => x.id === id)){
            let x = NavigationData.length-1;
            while(NavigationData[x].id !== id){
                dispatch(removeFileTransferRoute());
                x--;
            }
        }else{
            dispatch(updateFileTransferRoute({data: data, id:id}))
        }
    }

    const handleClick = (data) => {
        setIsLoading(true)
        setParentUUID(data.id)
        getFolders(data.name, data.id);
    }

    const handleClose = () => {
        setVisibilityOfUploadSection(true);
        setVisibilityOfAnimation(false);
        dispatch(emptyFileTransferRoute())
        dispatch(setFileTransferRouteUpdated())
    }


    useEffect(() => {
        if(createFolderModal === true || subscriptionVisibility === true){
            document.body.style.overflow = 'hidden';
        }
        else{
            document.body.style.overflow = '';
        }
    },[createFolderModal, subscriptionVisibility])

    useEffect(() => {
        setParentUUID(initialId[initialId.length-1].id);
        if(initialId.length !== 0){
            getFolders("library", initialId[initialId.length-1].id);
        }
        else{
            getFolders("library", "");
        }
    },[])

    return (
        // <UploadSubContainer>
        <>

            <FolderSelectionSection>
                <FoldersSection>

                    <HeadingSection>
                        <IconSection lang={lang} src={LeftArrowIcon} alt="arrow icon" onClick={() => {handleClose()}}/>
                        <Text>
                            <MainHeading>{FileSavingLanguage[lang].heading}</MainHeading>
                            <SubHeading>
                                {FileSavingLanguage[lang].subHeading}
                            </SubHeading>
                            <SubHeadingSmall>
                                {FileSavingLanguage[lang].subHeadingSmall}
                            </SubHeadingSmall>
                        </Text>
                    </HeadingSection>

                    {!isLoading ?
                    <>
                        <FileTransferNavigation getFolders={getFolders} setIsLoading={setIsLoading}/>
                        {folders?.length !== 0 ?
                        <ScrollableFolderNamesSection insideattachfile="true">
                        {folders.map((data, index) => {
                            if(data.type === 'folder')
                            return (
                                <FolderNameSection onClick={() => handleClick(data)} key={index} flexdirection="row" bordercolor="#f4f4f4" color="#252525" fontWeight="600">
                                    <img style={{width:"2rem"}} src={FolderIcon} alt='folder Icon' />
                                    <NavigationName>{data.name}</NavigationName>
                                </FolderNameSection>
                            )
                            return null;
                        })}
                        </ScrollableFolderNamesSection> 
                        :
                        <EmptyFolder insideattachfile='true'>
                            {lang === 'en' ? "No Folders Found" : "لم يتم العثور على مجلدات"}
                        </EmptyFolder>
                        } 
                    </>
                    :
                    <LoaderOuterContainer insideattachfile="true">
                        <Loader/>
                    </LoaderOuterContainer>
                    }

                    <FileAccessStatusSection>
                        {FileSavingLanguage[lang].fileAccessStatus}
                        <AccessButton bordercolor={borderColorSpecial} background={backgroundColorSpecial} onClick={() => handleSpecialClick()}>{FileSavingLanguage[lang].privateButton}</AccessButton>
                        <AccessButton bordercolor={borderColorGeneral} background={backgroundColorGeneral} onClick={() => handleGeneralClick()}>{FileSavingLanguage[lang].publicButton}</AccessButton>
                        
                        <NewFolderContainer lang={lang}>
                            <CreateNewFolderSection insideattachfile="true" onClick={() => setCreateFolderModal(true)}>
                                <StyledSVG width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 13.834H3.16667C2.72464 13.834 2.30072 13.6584 1.98816 13.3458C1.67559 13.0333 1.5 12.6093 1.5 12.1673V3.00065C1.5 2.55862 1.67559 2.1347 1.98816 1.82214C2.30072 1.50958 2.72464 1.33398 3.16667 1.33398H6.5L9 3.83398H14.8333C15.2754 3.83398 15.6993 4.00958 16.0118 4.32214C16.3244 4.6347 16.5 5.05862 16.5 5.50065V8.41732M12.3333 13.834H17.3333M14.8333 11.334V16.334" stroke="#CA56A1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </StyledSVG>
                                <NewFolderButton>{FileSavingLanguage[lang].newFolderButton}</NewFolderButton>
                            </CreateNewFolderSection>
                        </NewFolderContainer>
                    </FileAccessStatusSection>

                </FoldersSection>
                <ButtonsSection>

                    <FolderNameSection bordercolor="#9747FF" color="252525" fontWeight="normal">
                        <FileName>{FileSavingLanguage[lang].giveTitleToFile}</FileName>
                        <FileNameInput defaultValue={fileName} onChange={(e) => setEditedFileName(e.target.value)}></FileNameInput>
                    </FolderNameSection>

                    <StyledButton dissablebutton={"false"} onClick={() => setData()}>{FileSavingLanguage[lang].keepButton}</StyledButton>
                
                </ButtonsSection>
            </FolderSelectionSection>

            {createFolderModal ? 
            <FolderCreationModal setCreateFolderModal={setCreateFolderModal} type="createFolderAttachFile" IsPublic="false" folderId={parentUUID} getFolders={getFolders} currentFolder={currentFolder}/>
            : null}

            {subscriptionVisibility ? 
            <SubscriptionModal setSubscriptionVisibility={setSubscriptionVisibility} />
            : null}
        </>
    )
}

export default FileSavingSection