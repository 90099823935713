export const deleteLang = {
    ar:{
        TextDelete:"هل أنت متأكد أنك تريد حذف البطاقة؟",
        ParaDelete:"",
        Revert:"الرجوع",
        YesDelete:"نعم أحذف"
    },
    en:{
        TextDelete:'Are you sure you want to delete the card?',
        ParaDelete:'(What payment methods are available to transfer the required amount?) You will not be able to recover it again',
        Revert:'Retreat',
        YesDelete:'Delete'
    }
}