import React, { useEffect, useState } from "react";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import google from "../../Assets/Images/google.png";
import Facebook from "../../Assets/Images/facebook.png";
import "react-phone-input-2/lib/style.css";
import {
  AuthWrapper,
  HeadingContainer,
  LoginContainer,
  ParagraphContainer,
  FormContainer,
  Phone2Input,
  PasswordInput,
  Passwordvisible,
  ParagraphContainer2,
  ParaContainer,
  ButtonContainer,
  ButtonStyle,
  ViewContain,
  ViewLine,
  ViewText,
  SocialButton,
  ImgStyle,
  InputStyle2,
  Loader,
  LoaderCenter,
  ViewContain2,
  A,
  Error,
} from "./Auth.Style";
import { useDispatch, useSelector } from "react-redux";
import { loginDispatcher } from "../../Store/Slice/auth/Auth.Slice.js";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "../../Services/api/auth.js";
import { authlang } from "../data/auth.lang.js";

const Login = () => {
  const [value, setValue] = useState("sa");
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const isAuthanticated = useSelector((store) => store?.auth?.isAuthenticated);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const lang = useSelector((state) => state?.lang?.language);
  const [moberror,setMobError]=useState(false);

  //navigate to home if user is already logged in
  useEffect(() => {
    if (isAuthanticated) {
      navigate("/");
    }
  }, [isAuthanticated]);

  // Scroll to top when location changes
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  //password handler
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // button disable
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  useEffect(() => {
    isButtonDisabled();
  }, [password, value]);

  const isButtonDisabled = () => {
    if (!password || value.length===3||moberror) {
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
   
    const data = {
      phone_number: `${value}`,
      password: password,
    };

    dispatch(
      loginDispatcher(
        data,
        async () => {
          const isprofilecomplete = await Auth.getProfile();
          if (isprofilecomplete?.data?.data?.missing_data.length>0) {
            if (
              isprofilecomplete?.data?.data?.missing_data.length === 1 &&
              isprofilecomplete?.data?.data?.missing_data[0] === "email"
            ) {
              navigate("/");
            } else {
              navigate("/model");
            }
          } else {
            navigate("/");
          }
        },
        async () => {
          navigate("/verify");
        },
        lang
      )
    );
  };
  
  useEffect(() => {
    if(value.length >= 12){
      setValue(value.slice(3));
    }
  }, [value]);


  const validatePhoneNumber = (value) => {
    const nationalNumber = value.slice(3);
    if (nationalNumber.startsWith('5') && nationalNumber.length === 9) {
      setValue(nationalNumber);
      setMobError(false);
      return true;
    } else {
      if(nationalNumber.length === 0){
        setMobError(false);
        return true;
      }
      if(nationalNumber.startsWith('5') && nationalNumber.length <= 9){
        setMobError(true);
        return false;
      }
      setMobError(true);
      return false;
    }
  };

  return (
    <AuthWrapper>
      <LoginContainer>
        <HeadingContainer>{authlang[lang].Loginheading} </HeadingContainer>
        <ParagraphContainer>{authlang[lang].loginparagraph}</ParagraphContainer>
        <FormContainer>
          <Phone2Input
            country={value}
            disableDropdown={true}
            onChange={(data) => setValue(data)}
            placeholder="5XXXXXXXX"
            countryCodeEditable={false}
            isValid={(value, country) => validatePhoneNumber(value)}
          />
          {moberror && <Error>{"رقم الهاتف غير صحيح. يجب أن يبدأ بـ 5 وأن يتكون من 9 أرقام."}</Error>}
          <PasswordInput lang={lang}>
            <InputStyle2
              value={password}
              onChange={handlePasswordChange}
              type={visible ? "text" : "password"}
              placeholder={authlang[lang].password}
              lang={lang}
            />
            <Passwordvisible onClick={() => setVisible(!visible)}>
              {visible ? <BiSolidShow /> : <BiSolidHide />}
            </Passwordvisible>
          </PasswordInput>
          <ParaContainer style={{justifyContent:"center", display:"flex" }}>
            <ParagraphContainer2>
             <Link to='/forgot-password' style={{textDecoration:"none",Color:"black"}}> {authlang[lang].forgetpassword}</Link>
            </ParagraphContainer2>
          </ParaContainer>
          <ButtonContainer>
            <ButtonStyle
              type="submit"
              disabled={isButtonDisable}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? (
                <LoaderCenter>
                  <Loader />
                </LoaderCenter>
              ) : (
                authlang[lang].loginbutton
              )}
            </ButtonStyle>
          </ButtonContainer>
          <ViewContain>
            <ViewLine />
            <ViewText>{authlang[lang].logindescription}
            <A to={"/user/register"}>{authlang[lang].loginlink}</A>
            </ViewText>
            <ViewLine />
          </ViewContain>
          
        </FormContainer>
      </LoginContainer>
    </AuthWrapper>
  );
};

export default Login;
