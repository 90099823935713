export const UploadFileLanguage = {
    ar : {
        heading : "تعلم بتميز واستثمر",
        coloredHeading : "وقتك",
        subHeading : "بأذكى تقنيات الـ AI نبسطلك المادة ونلخصها لك قبل اختبارك ",
        pdf : "اسحب ملفك هنا أو أرفع الملف من جهازك",
        attachButton : "إرفاق الملف  ",
        heading1pub:"لا توجد نتائج بحث",
        subHeading1pub:"أرفع مادتك واترك التلخيص علينا",  
    },
    
    en : {
        heading : "Learn smart and invest",
        coloredHeading : "Your time",
        subHeading : "AI Summarize Files and Memos: Save time and enhance understanding for academic success",
        pdf : "Insert a PDF file or click to select from device",
        attachButton : "Attach a file",
        heading1pub:"لا توجد نتائج بحث",
        subHeading1pub:"يمكنك رفع ملفاتك مجانا والحصول على تجربة تعلم مثالية ",

    },
}

export const FileSavingLanguage = {
    ar : {
        heading : "حدد مكان حفظ الملف",
        subHeading :"بتنظيم ملفاتك في مجلدات مخصصة لكل موضوع، يمكنك...",
        subHeadingSmall :"نظّم ملفاتك في مجلدات خاصة لكل موضوع",
        newFolderButton : "مجلد جديد",
        libraryFolderName :"مكتبتي",
        keepButton : "حفظ",
        fileTransferButton : "نقل الملف",
        publicButton : "عام",
        privateButton : "خاص",
        giveTitleToFile: "ضع عنوان لملفك",
        fileAccessStatus : "حالة الوصول للملف",
    },
    
    en : {
        heading : "Select where to save the file",
        subHeading : "By organizing your files into dedicated folders for each topic, you can improve your tracking And share your performance more efficiently.",
        subHeadingSmall : "Organize your files into folders dedicated to each topic.",
        newFolderButton : "New folder",
        libraryFolderName : "My library",
        fileTransferButton : "File transfer",
        keepButton : "Keep",
        publicButton : "Public",
        privateButton : "Private",
        giveTitleToFile: "Give title to your file",
        fileAccessStatus : "File access status",
    },
}

