import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Register from "./Components/Auth/Register";
import HomeContainer from "./Container/HomeContainer";
import Layout from "./Layouts/Layout";
import Login from "./Components/Auth/Login";
import Model from "./Components/Auth/Model";
import PersonalDataform from "./Components/Profile/PersonalDataform";
import Library from "../src/Pages/Library";
import UploadFilePage from "./Pages/UploadFilePage";
import PDFView from "./Pages/PDFView";
import FlashStartLearn from './Components/FlashCardLearning/FlashStartLearn';
import FlashResult from './Components/FlashCardLearning/Result';
import StartLearning from './Components/FlashCardLearning/StartLearn';
import Result from "../src/Components/Result/Result.js";
import FlashCards from "./Components/FlashCards/FlashCards";
import FlashQuiz from './Components/FlashCardQuiz/FlashQuiz';
import QuizResult from './Components/FlashCardQuiz/QuizResult';
import Quiz from './Components/FlashCardQuiz/Quiz';
import PublicLibrary from "./Components/PublicLibrary/Publiclibrary.js";
import Overview from "./Components/PublicLibrary/overview.js";
import Otpverify from "./Components/Auth/Otpverify.js";
import Payment from "./Pages/Payment.js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PaymentStatus from "./Components/Payment/PaymentProcessing/PaymentStatus.js";
import Forget_password from "./Components/Auth/Forget_password.js";
import Update_password from "./Components/Auth/Update_password.js";

function App() {
  const lang = useSelector((state) => state?.lang?.language);
  // console.log(isAuthanticated);
  useEffect(() => {
    document.title = lang!=='en' ? 'متميز' : 'Momaez';
  }, [lang]);

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      //any error page
      error: <div>Not Found</div>,
      children: [
        {
          path: "/",
          element: <HomeContainer />,
        },
        {
          path: "/user/register",
          element: <Register />,
        },
        {
          path:"/forgot-password",
          element:<Forget_password/>
        },
        {
          path: "/user/login",
          element: <Login />,
        },
        {
          path: "/model",
          element: <Model />,
        },
        {
          path: "/upload",
          element: <UploadFilePage />,
        },
        {
          path: "library/:id?",
          element: <Library />,
        },
        {
          path: "/user/profile",
          element: <PersonalDataform />,
        },
        {
          path:'/flash-card/:id?',
          element:<FlashCards/>,
        },
        {
          path:'/Flash/StartLearning',
          element:<FlashStartLearn/>
        },
        {
          path:'/Flash/StartLearning/Cards',
          element:<StartLearning/>
        },
        {
          path:'/Flash/Result',
          element:<FlashResult/>
        },
        {
          path:'/Flash/StartQuiz',
          element:<FlashQuiz/>
        },
        {
          path:'/Flash/StarTest/Quiz',
          element:<Quiz/>
        },
        {
          path:'/Flash/TestResult',
          element:<QuizResult/>
        },
        {
          path: "/result",
          element: <Result />,
        },
        {
          path:"/public",
          element:<PublicLibrary/>
        },
        {
          path:"/update_password",
          element:<Update_password/>  
        },
        {
          path:"/overview",
          element:<Overview/>
        },
        {
          path:"/verify",
          element:<Otpverify/>
        },
        {
          path:"/forgot_password_verify",
          element:<Otpverify/>
        },
        {
          path: "/payment",
          element: <Payment />,
        },
      ],
    },
    {
      path: "/pdfview",
      element: <PDFView />,
    },
    {
      path: "/payment-status",
      element: <PaymentStatus/>,
    },
  ]);

  return (
    <>
      <RouterProvider router={appRouter} />
    </>
  );
}

export default App;
