
export const Herolang = {
  ar: {
    head1: "أرفع مادتك واترك التلخيص علينا",
    head2: "وخصص",
    head3:" تجربة التعلم فريدة وشاملة",
    desc: "بأذكى تقنيات الـ AI نبسط لك المادة ونلخصها لك قبل اختبارك",
    upload: "أدخل ملف PDF أو انقر للتحديد",
  },
  en: {
    head1: "Upload your file",
    head2: "and customize",
    head3:" a unique and comprehensive learning experience",
    desc: "Summarize files and notes with artificial intelligence: save time and enhance understanding to achieve academic success",
    upload: "Insert PDF or click to select",
  },
};

export const Storieslang = {
  ar: {
    head1:"رحلتك نحو التميز ",
    head2:"والنجاح بأعلى المستويات",
    desc: "استمتع بمغامرة تعلم مخصصة مع تميز، واستفد من الموارد الشاملة والدورات المميزة، وحقق النجاح الأكاديمي معنا، تكتشف الفرص الفريدة وتُعد مستقبلك المشرق!",
    teachers: "نخبة المعلمين",
    card1head: "العديد من المؤسسات التعليمية",
    card1subhead: "دروس تفاعلية",
    card2subhead: "محتوى علمي شامل",
    card3subhead: "شراء الفصول بشكل منفصل ",
     card4subhead: "اختبارات محاكية",
    card2Head: "تخرج الطلاب",
    card2subdesc2: "23,000+",
    card2desc: "أكثر من 2000 طالب انضموا إلينا",
  },
  en: {
    head1: "Your journey towards excellence",
    head2: "And success at the highest levels",
    desc: "Enjoy a personalized learning adventure with Tamayoz, benefit from comprehensive resources and distinctive courses, achieve academic success with us, discover unique opportunities and prepare for your bright future!",
    teachers: "Elite teachers",
    card1head: " educational institutions",
    card1subhead: " Interactive lessons",
    card2subhead: "Comprehensive scientific content",
    card3subhead: " Purchase chapters separately",
   
    card4subhead: "Practical exercises",
    
    card2Head: "Students graduated",
    card2subdesc2: "23,000+",
    card2desc: " More than 2000 Students have joined us",
  },
};

export const Aboutlang = {
  ar: {
    head1: "تعزيز الاستعداد لامتحاناتك واختباراتك",
    head2:
      "يظهر أثر التميز في عالم التعلم، حيث تظهر الأرقام رحلة النجاح الأكاديمي والتطور المستمر",
    feature1: "زيادة الثقة وتقليل القلق",
    feature1desc:
      "يساعد تمرين الطلاب للامتحانات في بناء الثقة في قدراتهم الأكاديمية. كلما كان لديهم المزيد من الفرص للتدريب والتمرين، كلما زادت ثقتهم في قدرتهم على التعامل مع الاختبارات. بالإضافة إلى ذلك، يمكن أن يقلل التمرين المنتظم من القلق والضغط النفسي الذي قد يصاحب الامتحانات.",
    feature2: "تحسين مهارات التحليل والتفكير",
    feature2desc:
      "يتطلب الاختبار حل المشكلات وتجاوز تحديات محددة في وقت محدود. من خلال ممارسة الاختبارات بانتظام، يمكن للطلاب تطوير مهارات التحليل والتفكير النقدي، وتعلم كيفية التعامل مع الضغط واتخاذ قرارات سريعة ومناسبة.",
    feature3: "تعزيز المعرفة والفهم",
    feature3desc:
      "يساعد تمرين الطلاب للامتحانات في تعزيز المعرفة والفهم العميق للمواد الأكاديمية. يجب على الطلاب الرد على",

    start: "جرب الآن ",
    education: "العديد من المؤسسات التعليمية",
    elite: "نخبة المعلمين",
  },
  en: {
    head1: "Enhance preparation for your exams and tests",
    head2:
      "The impact of Excellence is evident in the world of learning, where numbers show the journey of academic success and continuous development",
    feature1: "Increase Confidence and reduce Anxiety",
    feature1desc:
      "Practicing for tests helps students build confidence in their academic abilities. The more opportunity they have to practice and practice, the more confident they will be in their ability to handle tests. In addition, regular exercise can reduce anxiety and stress that may accompany exams.",
    feature2: "Improve analysis and thinking skills",
    feature2desc:
      "The test required solving problems and passing specific challenges in a limited time. By regularly practicing tests, students can develop analytical and critical thinking skills, learn how to deal with pressure and make quick and appropriate decisions.",
    feature3: "Strengthening knowledge and comprehension",
    feature3desc:
      "Practicing students for tests helps enhance knowledge and deep understanding of academic subjects. Students have to respond to",

    start: "Start Now",
    education: "Educational Institutions",
    elite: "Elite Teachers",
  },
};

export const Servicelang = {
  ar: {
    heading: "تعلم بتميزنبدأ معاك من المرحلة الثانوية ونستمر معاك خلال رحلتك الجامعية وبالنهاية نهيئك لتحصل على الوظيفة بكل تميز",
    descr:
      "استمتع بمغامرة تعليمية مخصصة مع تميز، واستفد من الموارد الشاملة والدورات المميزة، وحقق النجاح الأكاديمي معنا. اكتشف الفرص الفريدة وأعد مستقبلك المشرق.",
    list1head: "دروس تفاعلية",
    list1desc:
      "انغمس في تجربة ممتعة وفعالة، تجمع بين الدراسة الجذابة والتفاعل لتعزيز فهمك",
    list2head: "محتوى علمي شامل",
    list2desc:
      "ويحتضن جوانب متكاملة من المعرفة من خلال محتوى تعليمي يواكب أحدث التطورات ويتضمن مفاهيم أكاديمية رائدة",
    list3head: "شراء الفصل بشكل منفصل",
    list3desc:
      "يمكنك الاستفادة من شراء الدروس بشكل فردي وفقًا لاحتياجاتك الشخصية، مما يضمن لك التحكم الكامل في رحلتك التعليمية",
    list4head: "تمارين عملية",
    list4desc:
      "احصل على تجربة تعلم مقال من خلال التدريبات العملية التي تعكس تحديات الحياة الحقيقية، وذلك لتحسين مهاراتك وتطبيق المفاهيم التي تعلمتها",
    button: "ابدأ الان",
  },
  en: {
    heading:
      "Your Journey Towards EXCELLENCE and SUCCESS at the highest levels",
    descr:
      "Enjoy a personalized learning adventure with Tamayoz, benefit from comprehensive resources and distinctive courses, and achieve academic success with us. Discover unique opportunities and prepare your bright future.",
    list1head: "Interactive Lessons",
    list1desc:
      " Immerse yourself in a fun and effective experience,combining engaging study and interaction to enhance your understanding",
    list2head: "Comprehensive scientific content",
    list2desc:
      " It embraces integrated aspects of knowledge through educational content that keeps pace with the latest developments and includes leading academic concepts",
    list3head: "Purchase chapter seperately",
    list3desc:
      "You can benefit from purchasing classes individually according to your personal needs, ensuring you have complete control over your educational journey",
    list4head: "Practical Exercises",
    list4desc:
      "Get the experience of learning an essay through practical exerecise that reflect real-life challenges , in order to imporve your skills and apply the concepts you have learned.",
    button: "Join Now",
  },
};

export const HomeSearchlang={
  ar:{
    Heading1:"ذاكر بتميز لا تذاكر بجهد",
    span1:" تحليلات الذكاء ",
    span2:" الصناعي",
    Heading2:"وبطاقات الفلاش كارد  والامتحانات",
    description:"يمكنك الاستفادة من ملفات المكتبة العامه من خلال الملفات التي قام برفعها زملائك في نفس مرحلتك وتخصصك الدراسي لتجربة تعلم سريعة"
  },
  en:{
    Heading1:"An innovative learning experience for students, through",
    span1:"Artificial intelligence ",
    span2:"Analytics ",
    Heading2:"Flash cards and exams",
    description:"You can benefit from the public library files through the files uploaded by your colleagues in the same academic level and specialization for a quick learning experience"
  }
}
export const valuelang={
  ar:{
    heading1:"كيف نسـاعدك ؟",
    description:"",
    card1head:"الـ Ai يلخص لك المادة في لمح البصر",
    card1desc:"نساعدك تذاكر أهم النقاط الرئيسية بدون الحاجة لقراءة المادة كاملة",
    card2head:"عزز فهمك  ",
    card2desc:"نساعدك في حفظ محتوى المادة ونحثك على الاستعداد الفعّال لاختباراتك.",
    card3head:"راجع بفعالية ",
    card3desc:"نوفر لك ملخص للمفاهيم الرئيسية اللي بيخليك تراجع بأبسط طريقة   ",
    card4head:"قلل جهدك  ",
    card4desc:"نقدم لك لمعلومات بشكل مبسط وسلس مما يجعل المذاكرة أسهل وأقل تعقيداً  "
  },
  en:{
    heading1:"Added value for students",
    description:"The impact of Excellence is evident in the world of learning, where numbers show the journey of academic success and continuous development",
    card1head:"Save time and effort",
    card1desc:"We don't want you to forget a piece of information.. Our algorithm repeats the information once, twice, and three times at different times until you are sure you have mastered it!",
    card2head:"Increase understanding and comprehension",
    card2desc:"We don't want you to forget a piece of information.. Our algorithm repeats the information once, twice, and three times at different times until you are sure you have mastered it!",
    card3head:"Improve study and review",
    card3desc:"We don't want you to forget a piece of information.. Our algorithm repeats the information once, twice, and three times at different times until you are sure you have mastered it!",
    card4head:"Reduce stress and psychological pressure",
    card4desc:"We don't want you to forget a piece of information.. Our algorithm repeats the information once, twice, and three times at different times until you are sure you have mastered it!"
  }
}

export const worklang={
  ar:{
   heading:"ابدأ ذاكر بتميز !  ",
   description:"",
   step3head:"اختبر مستواك",
   step3desc:"في أسئلة اختيار من متعدد أو صح وخطأ أو إكمال الفراغ   ",
   step2head:"راجع معلوماتك",
   step2desc:"نوفر عليك الوقت والجهد في تلخيص المادة ومراجعتها",
   step1head:"أرفع ملفك",
   step1desc:"ونلخصه لك على هيئة فلاش كارد في ثواني",
  },
  en:{
    heading:"How it works?",
    description:"The impact of Excellence is evident in the world of learning, where numbers show the journey of academic success and continuous development",
    step3head:"Attach file",
    step3desc:"The test required solving problems and passing specific challenges in a limited time. By regularly practicing tests",
    step2head:"Flash cards",
    step2desc:"The test required solving problems and passing specific challenges in a limited time. By regularly practicing tests",
    step1head:"Conduct tests",
    step1desc:"The test required solving problems and passing specific challenges in a limited time. By regularly practicing tests",

  }
}