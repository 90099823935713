import React, { useEffect, useRef, useState } from 'react'
import { ButtonContainer, ButtonStyle, HeadingContainer, Loader, LoaderCenter, LoginContainer, Otp, OtpContainer, OtpInput, ParagraphContainer } from './Auth.Style';
import { authlang } from '../data/auth.lang';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiClient from '../../Config/index.config';
import { LoaderContainer } from '../UploadFile/UploadFile.styles';
import { RegisterEnd, loginStart, loginSuccess } from '../../Store/Slice/auth/Auth.Slice';

const Otpverify = () => {
    const location = useLocation()
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [otpError, setOtpError] = useState(null);
    const otpBoxReference = useRef([]);
    const navigate = useNavigate();
    const [resendotp, setResendotp] = useState(true);
    const [counter, setCounter] = useState(60);
    const lang = useSelector((state) => state?.lang?.language);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const phone =useSelector((state)=>state?.auth?.phone_number);
    const dispatch = useDispatch();
  
    function handleChange(value, index) {
      let newArr = [...otp];
      newArr[index] = value;
      setOtp(newArr);
  
      if(value && index <3){
        otpBoxReference.current[index + 1].focus()
      }
    }
  
    function handleBackspaceAndEnter(e, index) {
      if(e.key === "Backspace" && !e.target.value && index > 0){
        otpBoxReference.current[index - 1].focus()
      }
      if(e.key === "Enter" && e.target.value && index <3){
        otpBoxReference.current[index + 1].focus()
      }
    }

    const GetVerify = async () => {
      setLoading(true)
      try{
        if(location.pathname==="/forgot_password_verify"){
           const res= await ApiClient.post("/auth/forgot-password/verify-otp",{
            "phone_number": phone,
            "code": otp.join("")
          })
          setResendotp(true);
          sessionStorage.setItem("pass_token",res?.data?.data?.token);
          navigate("/update_password")
        }
        else{
          const response = await ApiClient.post("/auth/verify", {
            "phone_number": phone,
            "code": otp.join("")
          });
          setResendotp(true);
          if(response?.data?.data?.access_token){
            dispatch(loginSuccess(response.data));
            navigate("/model");
          }  
        }
      }
      catch(error){
          if(lang === "ar"){
            setOtpError("رمز التحقق غير صحيح، يرجى المحاولة مرة أخرى")
          }
          else{
            setOtpError("Wrong OTP Please Check Again")
          }
        setOtp(new Array(4).fill("")); 
        otpBoxReference.current[0].focus(); 
      }
      setLoading(false)
    }
  
    useEffect(() => {
      if(otp.join("").length===4){
        setDisabled(false);
      }
      else{
        setDisabled(true);
      }
     }, [otp]);

     useEffect(() => {
      let interval;
      if (resendotp && counter > 0) {
        interval = setInterval(() => {
          setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
      } else if (counter === 0) {
        setResendotp(false);
      }
      return () => clearInterval(interval);
    }, [resendotp, counter]);


     const handleResend = async () => {
       setResendotp(true);
       setCounter(60);
      try{
        const response = await ApiClient.post("/auth/resend-otp", {
          "phone_number": phone
        });
      }
      catch(error){
        setOtp(new Array(4).fill("")); 
        otpBoxReference.current[0].focus(); 
      }
    }
  return (
        <Otp>
        <LoginContainer>
            <HeadingContainer>{authlang[lang].otpheading}</HeadingContainer>
            <ParagraphContainer>{authlang[lang].otpparagraph}</ParagraphContainer>
            <OtpContainer>
                {otp.map((data, index) => (
                <OtpInput
                    key={index}
                    type="text"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    ref={(ref) => (otpBoxReference.current[index] = ref)}
                />
                ))}
                
            </OtpContainer>
              <button onClick={handleResend} disabled={resendotp} style={{backgroundColor:"white",border:"0",textDecoration: "underline"}}>
                {location.pathname==="/forgot_password_verify" ? "" :( resendotp ? `إعادة إرسال في ${counter} ثواني` : 'إعادة إرسال')}
              
              </button>
                {otpError ?
                <div>{otpError}</div>
                : null}
            <ButtonContainer style={{width:"80%"}}>
                <ButtonStyle style={{width:"70%"}}   onClick={() => {
                GetVerify();
                }} disabled={disabled}>
                  {loading ? 
                    <LoaderCenter>
                      <Loader />
                    </LoaderCenter>
                    :
                  authlang[lang].otpbutton
                  }
                </ButtonStyle>
            </ButtonContainer>
        </LoginContainer>
        </Otp>
  )
}

export default Otpverify
